import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="character-classes"></a>Character Classes</h2>
    <h3><a id="barbarian"></a>BARBARIAN</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/ph35_gallery/PHB35_PG25_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Alignment</b>:
Any nonlawful.</p>
    <p><b>Hit Die</b>: d12.</p>
    <h6>Class Skills</h6>
    <p className="initial">The barbarian&rsquo;s class skills (and the key
ability for each skill) are <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
(Str), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#handle-animal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Handle Animal</a> (Cha), <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a>
(Cha), <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
(Str), <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> (Wis), <a href="skillsAll.html#ride" style={{
        "color": "rgb(87, 158, 182)"
      }}>Ride</a>
(Dex), <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a> (Wis), and <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
(Str).</p>
    <p><b>Skill Points at 1st Level</b>: (4 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 4 + Int modifier.</p>
    <p><a id="table-the-barbarian"></a><b>Table: The Barbarian</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "8%"
          }}>Level</th>
          <th style={{
            "width": "15%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "8%"
          }}>Fort
Save</th>
          <th style={{
            "width": "8%"
          }}>Ref
Save</th>
          <th style={{
            "width": "8%"
          }}>Will
Save</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1</td>
          <td>+2</td>
          <td>+0</td>
          <td>+0</td>
          <td>Fast movement, illiteracy, rage 1/day</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+0</td>
          <td>+0</td>
          <td>Uncanny dodge</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>Trap sense +1</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>Rage 2/day</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>Improved uncanny dodge</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6/+1</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>Trap sense +2</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7/+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>Damage reduction 1/&mdash;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8/+3</td>
          <td>+6</td>
          <td>+2</td>
          <td>+2</td>
          <td>Rage 3/day</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+3</td>
          <td>+3</td>
          <td>Trap sense +3</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+10/+5</td>
          <td>+7</td>
          <td>+3</td>
          <td>+3</td>
          <td>Damage reduction 2/&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+11/+6/+1</td>
          <td>+7</td>
          <td>+3</td>
          <td>+3</td>
          <td>Greater rage</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+12/+7/+2</td>
          <td>+8</td>
          <td>+4</td>
          <td>+4</td>
          <td>Rage 4/day, trap sense +4</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+13/+8/+3</td>
          <td>+8</td>
          <td>+4</td>
          <td>+4</td>
          <td>Damage reduction 3/&mdash;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+14/+9/+4</td>
          <td>+9</td>
          <td>+4</td>
          <td>+4</td>
          <td>Indomitable will</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+15/+10/+5</td>
          <td>+9</td>
          <td>+5</td>
          <td>+5</td>
          <td>Trap sense +5</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+16/+11/+6/+1</td>
          <td>+10</td>
          <td>+5</td>
          <td>+5</td>
          <td>Damage reduction 4/&mdash;, rage 5/day</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+17/+12/+7/+2</td>
          <td>+10</td>
          <td>+5</td>
          <td>+5</td>
          <td>Tireless rage</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+18/+13/+8/+3</td>
          <td>+11</td>
          <td>+6</td>
          <td>+6</td>
          <td>Trap sense +6</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+19/+14/+9/+4</td>
          <td>+11</td>
          <td>+6</td>
          <td>+6</td>
          <td>Damage reduction 5/&mdash;</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+20/+15/+10/+5</td>
          <td className="last-row">+12</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">Mighty rage, rage 6/day</td>
        </tr>
      </tbody>
    </table>
    <h6>Class Features</h6>
    <p className="initial">All of the following are class features of the
barbarian.</p>
    <p><b>Weapon and Armor Proficiency</b>: A barbarian is proficient
with all simple and martial weapons, light armor, medium armor, and
shields (except tower shields).</p>
    <p><a id="barbarian-fast-movement"></a><b>Fast Movement (Ex)</b>: A barbarian&rsquo;s land speed is faster
than the norm for his race by +10 feet. This benefit applies only when
he is wearing no armor, light armor, or medium armor and not carrying a
heavy load. Apply this bonus before modifying the barbarian&rsquo;s speed
because of any load carried or armor worn.</p>
    <p><a id="barbarian-illiteracy"></a><b>Illiteracy</b>: Barbarians are the only characters who do
not automatically know how to read and write. A barbarian may spend 2
skill points to gain the ability to read and write all languages he is
able to speak. A barbarian who gains a level in any other class
automatically gains literacy. Any other character who gains a barbarian
level does not lose the literacy he or she already had.</p>
    <p><a id="barbarian-rage"></a><b>Rage (Ex)</b>: A barbarian can fly into a rage a certain
number of times per day. In a rage, a barbarian temporarily gains a +4
bonus to Strength, a +4 bonus to Constitution, and a +2 morale bonus on
Will saves, but he takes a &ndash;2 penalty to Armor Class. The increase in
Constitution increases the barbarian&rsquo;s hit points by 2 points per
level, but these hit points go away at the end of the rage when his
Constitution score drops back to normal. (These extra hit points are
not lost first the way temporary hit points are.) While raging, a
barbarian cannot use any Charisma-, Dexterity-, or Intelligence-based
skills (except for <a href="skillsAll.html#balance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Balance</a>, <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape
Artist</a>, <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a>, and <a href="skillsAll.html#ride" style={{
        "color": "rgb(87, 158, 182)"
      }}>Ride</a>),
the <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> skill, or any
abilities that require patience or
concentration, nor can he cast spells or activate magic items that
require a command word, a spell trigger (such as a wand), or spell
completion (such as a scroll) to function. He can use any feat he has
except <a href="featsAll.html#combat-expertise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Combat Expertise</a>, <a href="featsItc.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>item creation
feats</a>, and <a href="featsMtm.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>metamagic feats</a>. A
fit of rage lasts for a number of rounds equal to 3 + the character&rsquo;s
(newly improved) Constitution modifier. A barbarian may prematurely end
his rage. At the end of the rage, the barbarian loses the rage
modifiers and restrictions and becomes <a href="abilitiesAndConditions.html#fatigued" style={{
        "color": "rgb(87, 158, 182)"
      }}>fatigued</a> (&ndash;2 penalty to
Strength, &ndash;2 penalty to Dexterity, can&rsquo;t <a href="specialAttacks.html#charge" style={{
        "color": "rgb(87, 158, 182)"
      }}>charge</a>
or run) for the
duration of the current encounter (unless he is a 17th-level barbarian,
at which point this limitation no longer applies; see below).
      <p>{`A barbarian can fly into a rage only once per encounter. At 1st level
he can use his rage ability once per day. At 4th level and every four
levels thereafter, he can use it one additional time per day (to a
maximum of six times per day at 20th level). Entering a rage takes no
time itself, but a barbarian can do it only during his action, not in
response to someone else`}{`’`}{`s action.`}</p></p>
    <p><a id="barbarian-uncanny-dodge"></a><b>Uncanny Dodge (Ex)</b>: At 2nd level, a barbarian retains
his Dexterity bonus to AC (if any) even if he is caught <a href="abilitiesAndConditions.html#flat-footed" style={{
        "color": "rgb(87, 158, 182)"
      }}>flat-footed</a> or
struck by an <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible </a>attacker. However, he
still loses his Dexterity
bonus to AC if immobilized. If a barbarian already has uncanny dodge
from a different class, he automatically gains improved uncanny dodge
(see below) instead.</p>
    <p><a id="barbarian-trap-sense"></a><b>Trap Sense (Ex)</b>: Starting at 3rd level, a barbarian
gains a +1 bonus on Reflex saves made to avoid traps and a +1 dodge
bonus to AC against attacks made by traps. These bonuses rise by +1
every three barbarian levels thereafter (6th, 9th, 12th, 15th, and 18th
level). Trap sense bonuses gained from multiple classes stack.</p>
    <p><a id="barbarian-improved-uncanny-dodge"></a><b>Improved Uncanny Dodge (Ex)</b>: At 5th level and higher, a
barbarian can no longer be flanked. This defense denies a rogue the
ability to sneak attack the barbarian by flanking him, unless the
attacker has at least four more rogue levels than the target has
barbarian levels. If a character already has uncanny dodge (see above)
from a second class, the character automatically gains improved uncanny
dodge instead, and the levels from the classes that grant uncanny dodge
stack to determine the minimum level a rogue must be to flank the
character.</p>
    <p><a id="barbarian-damage-reduction"></a><b>Damage Reduction (Ex)</b>: At 7th level, a barbarian gains
Damage Reduction. Subtract 1 from the damage the barbarian takes each
time he is dealt damage from a weapon or a natural attack. At 10th
level, and every three barbarian levels thereafter (13th, 16th, and
19th level), this damage reduction rises by 1 point. Damage reduction
can reduce damage to 0 but not below 0.</p>
    <p><a id="barbarian-greater-rage"></a><b>Greater Rage (Ex)</b>: At 11th level, a barbarian&rsquo;s bonuses
to Strength and Constitution during his rage each increase to +6, and
his morale bonus on Will saves increases to +3. The penalty to AC
remains at &ndash;2.</p>
    <a id="barbarian-indomitable-will"></a><p><b>Indomitable Will (Ex)</b>: While in a rage, a barbarian of
14th level or higher gains a +4 bonus on Will saves to resist
enchantment spells. This bonus stacks with all other modifiers,
including the morale bonus on Will saves he also receives during his
rage.</p>
    <p><a id="barbarian-tireless-rage"></a><b>Tireless Rage (Ex)</b>: At 17th level and higher, a
barbarian no longer becomes <a href="abilitiesAndConditions.html#fatigued" style={{
        "color": "rgb(87, 158, 182)"
      }}>fatigued</a> at the end of his rage.</p>
    <p><a id="barbarian-mighty-rage"></a><b>Mighty Rage (Ex)</b>: At 20th level, a barbarian&rsquo;s bonuses
to Strength and Constitution during his rage each increase to +8, and
his morale bonus on Will saves increases to +4. The penalty to AC
remains at &ndash;2.</p>
    <h6><a id="ex-barbarians"></a>Ex-Barbarians</h6>
    <p className="initial">A barbarian who becomes lawful loses the
ability to rage and cannot gain more levels as a barbarian. He retains
all the other benefits of the class (damage reduction, fast movement,
trap sense, and uncanny dodge).</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      